* {
    border: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .inline-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #f5f6f7;
    text-align: center;
  }
  
  .inline-error h1 {
    font-size: 3em;
    line-height: 1;
    margin: 1.5rem 0;
  }
  
  .inline-error p {
    margin: 0 0 1.5em;
  }
  
  .inline-error .btn-link {
    background: hsl(223, 90%, 45%);
    border-radius: 1.5em;
    display: inline-block;
    outline: transparent;
    padding: 0.75em 1.5em;
    text-align: center;
    text-decoration: none;
    color: hsl(0, 0%, 100%);
    transition: background-color 0.15s linear;
  }
  
  .inline-error .btn-link:focus-visible,
  .inline-error .btn-link:hover {
    background: hsl(223, 90%, 65%);
  }
  
  .inline-error .btn-link:hover {
    text-decoration: none;
  }
  
  .inline-error .paper {
    display: block;
    margin: auto;
    overflow: visible;
    width: 100%;
    max-width: 224px;
    height: auto;
  }
  
  .inline-error .paper__fill {
    fill: hsl(0, 0%, 100%);
  }
  
  .inline-error .paper__outline,
  .inline-error .paper__lines {
    transition: stroke 0.3s;
  }
  
  .inline-error .paper__outline {
    stroke: hsl(223, 10%, 10%);
  }
  
  .inline-error .paper__lines {
    stroke: hsl(223, 10%, 70%);
  }
  
  .inline-error .paper__shadow {
    fill: hsl(223, 10%, 70%);
    transition: fill 0.3s;
  }
  
  .inline-error .paper__top,
  .inline-error .paper__bottom,
  .inline-error .paper__tear,
  .inline-error .paper__tear-fill {
    animation: paperTop 1.25s cubic-bezier(0.77, 0, 0.18, 1);
  }
  
  .inline-error .paper__top,
  .inline-error .paper__bottom {
    transform-origin: 0 148px;
    transition: transform 0.3s cubic-bezier(0.77, 0, 0.18, 1);
  }
  
  .inline-error .paper__top {
    transform: translate(0, 8px);
  }
  
  .inline-error .paper__bottom {
    animation-name: paperBottom;
    transform: translate(0, 42px);
  }
  
  .inline-error .paper__tear,
  .inline-error .paper__tear-fill {
    animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
  }
  
  .inline-error .paper__tear {
    animation-name: paperTear;
    stroke-dashoffset: 0;
  }
  
  .inline-error .paper__tear-fill {
    animation-name: paperTearFill;
  }
  
  .inline-error .paper__outline:hover .paper__top,
  .inline-error .paper__outline:hover .paper__bottom {
    transform: translate(0, 25px);
  }
  
  @keyframes paperTop {
    from,
    40% {
      animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
      transform: translate(0, 25px) rotate(0);
      transform-origin: 61px 148px;
    }
    70% {
      animation-timing-function: cubic-bezier(0.33, 1, 0.67, 1.5);
      transform: translate(0, 25px) rotate(-5deg);
      transform-origin: 61px 148px;
    }
    to {
      transform: translate(0, 8px) rotate(0);
      transform-origin: 0 148px;
    }
  }
  
  @keyframes paperBottom {
    from,
    40% {
      animation-timing-function: cubic-bezier(0.32, 0, 0.67, 0);
      transform: translate(0, 25px) rotate(0);
      transform-origin: 61px 148px;
    }
    70% {
      animation-timing-function: cubic-bezier(0.33, 1, 0.67, 1.5);
      transform: translate(0, 25px) rotate(5deg);
      transform-origin: 61px 148px;
    }
    to {
      transform: translate(0, 42px) rotate(0);
      transform-origin: 0 148px;
    }
  }
  
  @keyframes paperTear {
    from,
    40% {
      stroke-dashoffset: -198;
    }
    70%,
    to {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes paperTearFill {
    from,
    40% {
      width: 187px;
    }
    70%,
    to {
      width: 0;
    }
  }
  