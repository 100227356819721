#home {
  margin: 0;
  font-family: 'Mona Sans', sans-serif;
  background-color: #0c1015;
  color: #fff;
  display: flex;
  flex-direction: row; /* Change to row to align logo and cards horizontally */
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.logo-card {
  position: sticky; /* Sticky to stay in place while scrolling */
  top: 50%;
  transform: translateY(-50%);
  max-width: 300px;
  filter: drop-shadow(0px 0px 5px var(--color)) drop-shadow(0px 0px 15px var(--color));
  flex-shrink: 0; /* Prevent the logo from shrinking */
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 10px;
  width: calc(100% - 320px); /* Adjusted width to accommodate the logo card */
  margin-left: 60px; /* Ensure some space between logo card and card container */
  padding-top: 20px;
}

#home .card__border,
#home .card__border-line,
#home .card__inner {
  clip-path: polygon(50% 0, 100% 20%, 100% 80%, 50% 100%, 0% 80%, 0% 20%);
}

#home .card__border {
  position: absolute;
  width: 98%;
  height: 102%;
  background: var(--color);
  left: 1%;
  top: 1%;
}

#home .card__border-line {
  position: absolute;
  width: 95%;
  height: 99%;
  left: 2.5%;
  top: 2.5%;
  background: linear-gradient(to bottom, hsl(42, 90%, 72%) 33%, hsl(0, 0%, 0%) 70%);
}

#home .card__inner {
  background: linear-gradient(179deg, var(--color), #e3a83b 90%);
  padding: 0.5rem 0.2rem 0rem;
  width: 92%;
  margin-top: 4.8%;
  margin-left: 4%;
}

#home .card__img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

#home .uis-logo {
  width: 100%;
  max-width: 200px;
  object-fit: contain;
}

#home .card__type {
  width: min-content;
  margin: auto;
  padding: 0 10%;
  border-radius: 5px;
  background: var(--bg-color);
  color: var(--text-color);
  font-size: 1.5rem;
  margin-top: -6%;
}

#home .card__text {
  position: relative;
  padding-bottom: 16%;
}

#home .card__text:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url('https://i.pinimg.com/originals/80/7d/ce/807dcedf950725fea6b935cdb6ffb4a6.jpg');
  opacity: 0.3;
  z-index: -1;
}

#home .name {
  font-size: 2.8rem;
  margin: 2% 0 0;
  position: relative;
}

#home .name:before {
  content: '';
  background: linear-gradient(to right, transparent, #3730305c, black, #0000004f, transparent);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5%;
}

#home .points {
  font-size: 4rem;
  margin: 0rem 0 10%;
  position: relative;
}

#home .points:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
  width: 25%;
  height: 5%;
  background: black;
}

#home .card__circuit-left,
#home .card__circuit-bottom,
#home .card__circuit-right {
  position: absolute;
}

#home .card__circuit-left {
  top: 0;
  left: -96px;
  width: 100px;
  height: 100%;
}

#home .card__circuit-bottom {
  bottom: -75px;
  left: 10px;
  width: 100%;
  height: 80px;
}

#home .card__circuit-right {
  top: 0;
  right: -91px;
  width: 100px;
  height: 100%;
}

#home .static-path {
  stroke-opacity: 1;
}

#home .animated-path {
  animation: draw 14s linear infinite;
}

#home .path-2 {
  animation-delay: 1s;
}

@keyframes draw {
  0% {
    stroke-dashoffset: 2000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@media screen and (max-width: 2000px) {
  .card-container {
    gap: 5px; /* Decreased gap */
    grid-template-columns: repeat(auto-fit, minmax(240px, 0.5fr));
  }

  .nft {
    max-width: 240px; /* Smaller than default but not too small */
    margin: 1.5rem auto;
    padding: 0.8rem;
  }

  .nft .main {
    padding: 0.76rem;
  }

  .nft .tokenImage {
    height: 230px;
  }

  .nft .description {
    margin: .4rem 0;
    font-size: 0.9rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.9rem;
  }

  .nft h2 {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1675px) {
  .card-container {
    gap: 2px;
    grid-template-columns: repeat(auto-fit, minmax(213px, 1fr));
  }

  .nft {
    max-width: 212px;
    margin: 1.38rem auto;
    padding: 0.8rem;
  }

  .nft .main {
    padding: 0.5rem;
  }

  .nft .tokenImage {
    height: 212px;
  }

  .nft .description {
    margin: .35rem 0;
    font-size: 0.8rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.82rem;
  }

  .nft h2 {
    font-size: 1.34rem;
  }
}

@media screen and (max-width: 1505px) {
  .card-container {
    gap: 1px;
    grid-template-columns: repeat(auto-fit, minmax(189px, 1fr));
  }

  .nft {
    max-width: 190px;
    margin: 1rem auto;
    padding: 0.5rem;
  }

  .nft .main {
    padding: 0.5rem;
  }

  .nft .tokenImage {
    height: 180px;
  }

  .nft .description {
    margin: .2rem 0;
    font-size: 0.7rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.7rem;
  }

  .nft h2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1430px) {
  .card-container {
    gap: -5px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    margin-left: 62px;
  }

  .logo-card {
    max-width: 300px;
  }

  #home .card__img {
    padding: 0.5rem;
  }

  #home .uis-logo {
    max-width: 150px;
  }

  #home .card__circuit-left {
    left: -86px;
    width: 90px;
  }

  #home .card__circuit-bottom {
    bottom: -65px;
    height: 70px;
  }

  #home .card__circuit-right {
    right: -80px;
    width: 90px;
  }

  #home .card__type {
    font-size: min(1.3rem, 5vw);
  }

  #home .name {
    font-size: min(12vw, 2.8rem);
  }

  #home .points {
    font-size: min(15vw, 3.8rem);
  }

  #home .card__text {
    padding-bottom: 12%;
  }
}

@media screen and (max-width: 1100px) {
  .card-container {
    gap: 3px;
    grid-template-columns: repeat(auto-fit, minmax(131px, 2fr));
    margin-left: 55px;
  }

  .logo-card {
    max-width: 300px;
  }

  #home .card__img {
    padding: 0.5rem;
  }

  #home .uis-logo {
    max-width: 150px;
  }

  #home .card__circuit-left {
    left: -65px;
    width: 70px;
  }

  #home .card__circuit-bottom {
    bottom: -49px;
    height: 50px;
  }

  #home .card__circuit-right {
    right: -60px;
    width: 70px;
  }

  #home .card__type {
    font-size: min(1rem, 3vw);
  }

  #home .name {
    font-size: min(10vw, 2rem);
  }

  #home .points {
    font-size: min(12vw, 3rem);
  }

  #home .card__text {
    padding-bottom: 9%;
  }
}

@media screen and (max-width: 900px) {
  .card-container {
    gap: -10px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 0.95fr));
    margin-left: 62px;
  }

  .home-container {
    max-width: 800px;
  }

  .logo-card {
    max-width: 300px;
  }

  #home .card__img {
    padding: 0.5rem;
  }

  #home .uis-logo {
    max-width: 120px;
  }

  #home .card__circuit-left {
    left: -68px;
    width: 70px;
  }

  #home .card__circuit-bottom {
    bottom: -57px;
    height: 60px;
    left: 6px;
  }

  #home .card__circuit-right {
    right: -62px;
    width: 70px;
  }

  #home .card__type {
    font-size: min(1.2rem, 5vw);
  }

  #home .name {
    font-size: min(10vw, 2.5rem);
  }

  #home .points {
    font-size: min(12vw, 3.5rem);
  }

  #home .card__text {
    padding-bottom: 12%;
  }
}

@media screen and (max-width: 687px) {
  .card-container {
    gap: -10px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 0.95fr));
    margin-left: 62px;
  }

  .home-container {
    max-width: 600px;
  }

  .logo-card {
    max-width: 300px;
  }

  #home .card__img {
    padding: 0.5rem;
  }

  #home .uis-logo {
    max-width: 100px;
  }

  #home .card__circuit-left {
    left: -55px;
    width: 60px;
  }

  #home .card__circuit-bottom {
    bottom: -45px;
    height: 50px;
    left: 6px;
  }

  #home .card__circuit-right {
    right: -50px;
    width: 60px;
  }

  #home .card__type {
    font-size: min(1.1rem, 5vw);
  }

  #home .name {
    font-size: min(9vw, 2.2rem);
  }

  #home .points {
    font-size: min(11vw, 3rem);
  }

  #home .card__text {
    padding-bottom: 12%;
  }
}

@media screen and (max-width: 655px) {
  .card-container {
    gap: 10px;
    margin-left: 35px;
    grid-template-columns: repeat(2, 90%); /* Ensure 2 columns side by side */
  }

  .home-container {
    max-width: 600px;
  }

  .logo-card {
    max-width: 250px;
  }

  #home .card__img {
    padding: 0.5rem;
  }

  #home .uis-logo {
    max-width: 100px;
  }

  #home .card__circuit-left {
    left: -35px;
    width: 40px;
  }

  #home .card__circuit-bottom {
    bottom: -28px;
    height: 30px;
    left: 3px;
  }

  #home .card__circuit-right {
    right: -33px;
    width: 40px;
  }

  #home .card__type {
    font-size: min(1.1rem, 5vw);
  }

  #home .name {
    font-size: min(9vw, 2.2rem);
  }

  #home .points {
    font-size: min(11vw, 3rem);
  }

  #home .card__text {
    padding-bottom: 8%;
  }
}

@media screen and (max-width: 500px) {
  .card-container {
    gap: 5px;
    grid-template-columns: repeat(2, 50%);
    margin-left: 20px;
    width: calc(100% - 50px); /* Ensure some space on the sides */
  }

  .logo-card {
    max-width: 200px;
    left: 10px; /* Adjust the logo position */
  }

  .home-container {
    max-width: 500px;
    padding-top: 10px;
  }

  .nft {
    max-width: 150px;
    margin: 0.5rem auto;
    padding: 0.3rem;
  }

  .nft .main {
    padding: 0.3rem;
  }

  .nft .tokenImage {
    height: 130px;
  }

  .nft .description {
    margin: 0.2rem 0;
    font-size: 0.5rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.5rem;
  }

  .nft h2 {
    font-size: 0.8rem;
  }

  #home .card__circuit-left {
    left: -30px;
    width: 30px;
  }

  #home .card__circuit-bottom {
    bottom: -30px;
    height: 30px;
    left: 3px;
  }

  #home .card__circuit-right {
    right: -30px;
    width: 30px;
  }

  #home .card__type {
    font-size: min(1rem, 4vw);
  }

  #home .name {
    font-size: min(8vw, 2rem);
  }

  #home .points {
    font-size: min(10vw, 2.5rem);
  }

  #home .card__text {
    padding-bottom: 4%;
  }
}
