@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,700');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap');

#hris * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#hris .slider__navi {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  z-index: 999;
}

#hris .slider__navi a {
  display: block;
  height: 15px;
  width: 30px;
  margin: 40px 0;
  text-indent: -9999px;
  box-shadow: none;
  border: none;
  background: rgba(255, 255, 255, 0.5);
}

#hris .slider__navi a.active {
  background: rgba(255, 255, 255, 1);
}

#hris {
  position: relative;
  font-size: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  min-height: 100vh;
  overflow: hidden;
  background-color: #0c1015;
}

#hris .slider__warpper {
  height: 100%;
}

#hris .flex__container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  z-index: 1;
}

#hris .flex__container.flex--active {
  z-index: 2;
}

#hris .text--sub {
  font-size: 12px;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  margin-bottom: 40px;
  color: #fff;
}

#hris .logo-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px; /* Add space below the logo */
}

#hris .logo-image {
  width: 230px; /* Adjust the size as needed */
  height: auto;
}

#hris .text--normal {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 22px;
  margin-top: 25px;
}

#hris .text__background {
  font-family: 'Poppins', sans-serif;
  position: absolute;
  left: 72px;
  bottom: -60px;
  color: rgba(255, 255, 255, 0.1);
  font-size: 170px;
  font-weight: 700;
  z-index: 1;
}

#hris .flex__item {
  height: 100%;
  color: #fff;
  transition: transform 0.1s linear;
}

#hris .flex__item--left {
  display: flex;
  align-items: center;
  width: 65%;
  transform-origin: left bottom;
  transition: transform 0.1s linear 0.4s;
  opacity: 0;
  position: relative;
  overflow: hidden;
  background-color: #0c1015;
}

#hris .flex__item--right {
  width: 35%;
  transform-origin: right center;
  transition: transform 0.1s linear 0s;
  opacity: 0;
  background-color: #1b1f24;
}

#hris .flex--preStart .flex__item--left,
#hris .flex--preStart .flex__item--right,
#hris .flex--active .flex__item--left,
#hris .flex--active .flex__item--right {
  opacity: 1;
}

#hris .flex__item--left .flex__content {
  margin-left: 80px;
  width: 55%;
  opacity: 1;
  transform: translate3d(0,0,0);
  transition: transform 0.2s linear 0.2s, opacity 0.1s linear 0.2s;
  position: relative;
  z-index: 2;
}


/* Animate-START point */
#hris .flex__container.animate--start .flex__content {
  transform: translate3d(0,-200%,0);
  opacity: 0;
}

#hris .flex__container.animate--start .pokemon__img {
  transform: translate3d(-200px,0,0);
  opacity: 0;
}

/* Animate-END point */
#hris .flex__container.animate--end .flex__item--left {
  transform: scaleY(0);
}

#hris .flex__container.animate--end .flex__item--right {
  transform: scaleX(0);
}

#hris .flex__container.animate--end .flex__content {
  transform: translate3d(0,200%,0);
  opacity: 0;
}

#hris .flex__container.animate--end .pokemon__img {
  transform: translate3d(200px,0,0);
  opacity: 0;
}

.btn {
  display: inline-block;
  color: #ffffff;
  padding: 32px;
  position: relative;
  letter-spacing: 1px;
  margin-top: 25px;
}

.btn__circle,
.btn__text,
.btn__white-circle {
  position: absolute;
}

.btn__circle {
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 100%;
  width: 100%;
  box-shadow: 0 0 1px 1px #fff;
  transition: 0.3s linear;
}

.btn__white-circle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background: #ffffff;
  display: flex;
  transition: 0.3s ease-in-out;
}

.btn__white-circle svg {
  width: 24px;
  height: 24px;
  margin: auto;
}

.btn__text {
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  z-index: 2;
  padding: 24px 8px;
  transition: 0.3s linear;
}

.btn:hover .btn__circle {
  transform: scale(0);
}

.btn:hover .btn__white-circle {
  transform: translate(-50%, -50%) scale(1);
}

.btn:hover .btn__text {
  transform: translate(40px, -50%);
}

.content {
  background: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 980px) {
  #hris .text__background {
    font-size: 100px;
    left: 90px;
    bottom: 0;
  }
}
/* Media query for small screens */
@media (max-width: 500px) {
  /* Reduce the font sizes */
  #hris .text--sub {
    font-size: 8px;
    letter-spacing: 0.3rem;
    margin-bottom: 20px;
  }

  #hris .text--normal {
    font-size: 9px;
    line-height: 16px;
    margin-top: 15px;
  }

  #hris .text__background {
    font-size: 50px;
    left: 20px;
    bottom: 0;
  }

  /* Adjust the logo image size */
  #hris .logo-image {
    width: 100px;
  }

  /* Reduce padding and margin for buttons */
  .btn {
    padding: 12px;
    margin-top: 10px;
  }

  .btn__text {
    padding: 12px 4px;
  }

  .btn__white-circle {
    width: 30px;
    height: 30px;
  }

  .btn__white-circle svg {
    width: 14px;
    height: 14px;
  }

  /* Adjust navigation buttons */
  #hris .slider__navi {
    right: 10px;
  }

  #hris .slider__navi a {
    height: 10px;
    width: 25px;
    margin: 20px 0;
  }

  /* Adjust flex item sizes */
  #hris .flex__item--left {
    width: 75%;
  }

  #hris .flex__item--right {
    width: 25%;
  }

  #hris .flex__item--left .flex__content {
    margin-left: 40px;
    width: 70%;
  }
}
