/* Import a nice font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%); /* Gradient background */
  /* Alternatively, you can use a background image */
  /* background: url('path/to/your/image.jpg') no-repeat center center; */
  /* background-size: cover; */
}

.svg-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-image {
  width: 200px; /* Make the SVG bigger */
  height: auto;
  animation: pulse 2s ease-in-out infinite; /* Change the animation */
}

.loading-bar-wrapper {
  width: 80%; /* Width of the loading bar container */
  max-width: 300px; /* Max width of the loading bar container */
  margin-top: 20px;
  height: 10px; /* Height of the loading bar */
  background-color: #e0e0e0; /* Background color of the loading bar container */
  border-radius: 5px; /* Rounded corners */
  overflow: hidden;
}

.loading-bar {
  width: 0;
  height: 100%;
  background-color: #333;
  animation: load 4s forwards; /* Ensure the animation runs to completion and then stops */
}

.loading-text {
  margin-top: 10px;
  font-size: 1.2rem; /* Make the text bigger */
  color: #333;
  font-family: 'Poppins', sans-serif; /* Apply the nice font */
  animation: fadeIn 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes fadeIn {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
