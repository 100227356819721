/* Import the font */
@import url('https://fonts.googleapis.com/css2?family=Mona+Sans:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Mona Sans', sans-serif;
  background-color: #0c1015;
  color: #fff;
  font-size: 15px;
}

.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(12, 16, 21, 0.8);
  backdrop-filter: blur(3px);
  transition: all 0.3s;
  z-index: 1000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.logo-svg {
  width: 70px; /* Adjusted size */
  height: 70px; /* Adjusted size */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  flex-grow: 1;
}

.nav-links .nav-item {
  position: relative;
}

.nav-links .nav-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: inherit;
  padding: 10px 15px;
  transition: color 0.3s;
}

.nav-links .nav-link:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  display: block;
  background: #fff;
  transition: width 0.3s;
  left: 50%;
  transform: translateX(-50%);
}

.nav-links .nav-link:hover:after,
.nav-links .nav-link.active:after {
  width: 100%;
}

.nav-links .nav-link:hover,
.nav-links .nav-link.active {
  color: #fff;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  max-width: 300px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px;
}

.search-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}

.search-input {
  flex: 1;
  border: none;
  background: transparent;
  color: #fff;
  padding: 5px;
}

.search-input::placeholder {
  color: #ccc;
}

.hotkey-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 2rem;
  color: #fff;
  transition: transform 0.3s ease-in-out;
}

.menu-icon:hover {
  transform: rotate(90deg);
}

@media (max-width: 929px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    background-color: rgba(12, 16, 21, 0.8);
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    padding: 20px 0;
    text-align: center;
  }

  .nav-links.active {
    display: flex;
  }

  .search-container {
    display: none;
  }
}

.scrolled-navbar {
  display: none;
  justify-content: space-between;
  padding: 10px;
}

.scrolled-navbar.visible {
  display: flex;
  background-color: rgba(12, 16, 21, 0.8);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
}

.navbar-buttons {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .navbar-buttons {
    display: none;
  }
}

section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #0c1015;
  color: #fff;
  margin-top: 15px;
}

#home {
  background: #222;
}

#hris {
  background: #111;
}

#dbest {
  background: #222;
}

#track {
  background: #0c1015;
}
