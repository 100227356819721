@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add custom styles if needed */
body {
  @apply m-0 p-0 box-border;
}

.full-screen-section {
  @apply min-h-screen flex items-center justify-center;
}
