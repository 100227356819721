/* Import the font */
@import url('https://fonts.googleapis.com/css2?family=Mona+Sans:wght@400;700&display=swap');

/* General Styles */
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Mona Sans', sans-serif;
  background-color: #0c1015;
  color: #fff;
}

.dashboard {
  width: 100%;
  background-color: #0c1015;
  overflow: hidden; /* Ensure overflow is hidden */
  margin: 0; /* No margin */
  padding: 0; /* No padding */
}

.section {
  overflow: hidden; /* Ensure each section has overflow hidden */
  padding: 20px; /* Add padding to sections */
  margin: 20px auto; /* Center sections and add margin */
  max-width: 2000px; /* Set maximum width for sections */
  border-radius: 10px; /* Round the corners */
}

@media screen and (max-width: 1800px) {
  .section {
    max-width: 2000px; /* Adjust maximum width for smaller screens */
    padding: 20px; /* Adjust padding for smaller screens */
    margin: 20px auto; /* Adjust margin for smaller screens */
  }
}

@media screen and (max-width: 1400px) {
  .section {
    max-width: 1500px; /* Adjust maximum width for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
    margin: 15px auto; /* Adjust margin for smaller screens */
  }
}

@media screen and (max-width: 900px) {
  .section {
    max-width: 800px; /* Adjust maximum width for smaller screens */
    padding: 15px; /* Adjust padding for smaller screens */
    margin: 15px auto; /* Adjust margin for smaller screens */
  }
}

@media screen and (max-width: 600px) {
  .section {
    max-width: 600px; /* Adjust maximum width for even smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
    margin: 10px auto; /* Adjust margin for smaller screens */
  }
}
