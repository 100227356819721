/* Original Card CSS */
.nft {
  user-select: none;
  max-width: 250px;
  margin: 2rem auto;
  border: 1px solid #ffffff22;
  background-color: #282c34;
  background: linear-gradient(0deg, rgba(40, 44, 52, 1) 0%, rgba(17, 0, 32, .5) 100%);
  box-shadow: 0 7px 20px 5px #00000088;
  border-radius: .7rem;
  overflow: hidden;
  transition: .5s all;
  position: relative;
}

.nft hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #88888855;
  margin-top: 0;
}

.nft ins {
  text-decoration: none;
}

.nft .main {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 1rem;
}

.nft .tokenImage {
  border-radius: .5rem;
  max-width: 100%;
  height: 250px;
  object-fit: cover;
}

.nft .description {
  margin: .5rem 0;
  color: #a89ec9;
}

.nft .tokenInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft .tokenInfo .price {
  display: flex;
  align-items: center;
  color: #ee83e5;
  font-weight: 700;
}

.nft .tokenInfo .price ins {
  margin-left: -.3rem;
  margin-right: .5rem;
}

.nft .tokenInfo .duration {
  display: flex;
  align-items: center;
  color: #a89ec9;
  margin-right: .2rem;
}

.nft .tokenInfo .duration ins {
  margin: .5rem;
  margin-bottom: .4rem;
}

.nft:hover {
  border: 1px solid #ffffff44;
  box-shadow: 0 7px 50px 10px #000000aa;
  transform: scale(1.015);
  filter: brightness(1.3);
}

.nft::before {
  position: absolute;
  content: "";
  box-shadow: 0 0 100px 40px #ffffff08;
  top: -10%;
  left: -100%;
  transform: rotate(-45deg);
  height: 60rem;
  width: 60rem;
  transition: none;
}

.nft:hover::before {
  animation: shine 1s ease-in-out forwards;
}

@keyframes shine {
  0% {
    top: -10%;
    left: -100%;
  }
  50% {
    top: -30%;
    left: 150%;
  }
  100% {
    top: -10%;
    left: -100%;
  }
}

/* Media Queries for width */
@media screen and (max-width: 2000px) {
  .nft {
    max-width: 245px;
    margin: 1.4rem auto;
    padding: 0.8rem;
  }

  .nft .main {
    padding: 0.68rem;
  }

  .nft .tokenImage {
    height: 230px;
  }

  .nft .description {
    margin: .35rem 0;
    font-size: 0.85rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.86rem;
  }

  .nft h2 {
    font-size: 1.35rem;
  }
}

@media screen and (max-width: 1675px) {
  .nft {
    max-width: 215px;
    margin: 1.38rem auto;
    padding: 0.8rem;
  }

  .nft .main {
    padding: 0.5rem;
  }

  .nft .tokenImage {
    height: 212px;
  }

  .nft .description {
    margin: .35rem 0;
    font-size: 0.8rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.82rem;
  }

  .nft h2 {
    font-size: 1.26rem;
  }
}

@media screen and (max-width: 1505px) {
  .nft {
    max-width: 190px;
    margin: 1rem auto;
    padding: 0.5rem;
  }

  .nft .main {
    padding: 0.5rem;
  }

  .nft .tokenImage {
    height: 180px;
  }

  .nft .description {
    margin: .2rem 0;
    font-size: 0.7rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.7rem;
  }

  .nft h2 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1375px) {
  .nft {
    max-width: 160px;
    margin: 0.6rem auto;
    padding: 0.4rem;
  }

  .nft .main {
    padding: 0.4rem;
  }

  .nft .tokenImage {
    height: 150px;
  }

  .nft .description {
    margin: .1rem 0;
    font-size: 0.6rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.6rem;
  }

  .nft h2 {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 1100px) {
  .nft {
    max-width: 140px;
    margin: 0.45rem auto;
    padding: 0.3rem;
  }

  .nft .main {
    padding: 0.3rem;
  }

  .nft .tokenImage {
    height: 130px;
  }

  .nft .description {
    margin: .1rem 0;
    font-size: 0.55rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.55rem;
  }

  .nft h2 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 900px) {
  .nft {
    max-width: 140px;
    margin: 0.45rem auto;
    padding: 0.3rem;
  }

  .nft .main {
    padding: 0.3rem;
  }

  .nft .tokenImage {
    height: 130px;
  }

  .nft .description {
    margin: .1rem 0;
    font-size: 0.5rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.5rem;
  }

  .nft h2 {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 670px) {
  .nft {
    max-width: 130px;
    margin: 0.4rem auto;
    padding: 0.2rem;
  }

  .nft .main {
    padding: 0.2rem;
  }

  .nft .tokenImage {
    height: 120px;
  }

  .nft .description {
    margin: .1rem 0;
    font-size: 0.4rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.45rem;
  }

  .nft h2 {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 655px) {
  .nft {
    max-width: 130px;
    margin: 0.4rem auto;
    padding: 0.2rem;
  }

  .nft .main {
    padding: 0.2rem;
  }

  .nft .tokenImage {
    height: 120px;
  }

  .nft .description {
    margin: .1rem 0;
    font-size: 0.4rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.45rem;
  }

  .nft h2 {
    font-size: 0.65rem;
  }
}

@media screen and (max-width: 500px) {
  .nft {
    max-width: 105px;
    margin: 0.4rem auto;
    padding: 0.2rem;
  }

  .nft .main {
    padding: 0.2rem;
  }

  .nft .tokenImage {
    height: 90px;
  }

  .nft .description {
    margin: .1rem 0;
    font-size: 0.41rem;
  }

  .nft .tokenInfo .price,
  .nft .tokenInfo .duration {
    font-size: 0.4rem;
  }

  .nft h2 {
    font-size: 0.5rem;
  }
}
@media screen and (max-width: 448px) and (max-height: 850px) {
  .card-container.stacked {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .card-container.stacked .nft {
    width: 100%;
    position: absolute;
    top: 50%;
    left: calc(15%); /* Position next to logo card */
    transform: translateY(-50%);
    transition: transform 200ms;
  }

  .card-container.stacked .nft:nth-last-child(n + 4) {
    --y: calc(-50% + -30px);
    transform: translateY(var(--y)) scale(0.9);
    box-shadow: 0 0 1px 1px #00000003;
  }

  .card-container.stacked .nft:nth-last-child(3) {
    --y: calc(-50% + -15px);
    transform: translateY(var(--y)) scale(0.95);
  }

  .card-container.stacked .nft:nth-last-child(2) {
    --y: calc(-50%);
    transform: translateY(var(--y)) scale(1);
  }

  .card-container.stacked .nft:nth-last-child(1) {
    --y: calc(-50% + 15px);
    transform: translateY(var(--y)) scale(1.05);
  }

  .card-container.stacked .nft:hover {
    z-index: 10;
  }
}

@keyframes swap {
  50% {
    transform: translate(-50%, calc(var(--y) - 250px)) scale(0.85) rotate(-5deg);
    animation-timing-function: ease-in;
  }
  100% {
    transform: translate(-50%, calc(var(--y) - 15px)) scale(0.85);
    z-index: -1;
  }
}
