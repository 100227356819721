:root {
  --steps: 10;
  --saturation: 80%;
  --lightness: 60%;
  --hue-offset: 320;
  --duration: 5000ms;
  --color-01: hsl(calc(360 / var(--steps) *  1 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-02: hsl(calc(360 / var(--steps) *  2 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-03: hsl(calc(360 / var(--steps) *  3 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-04: hsl(calc(360 / var(--steps) *  4 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-05: hsl(calc(360 / var(--steps) *  5 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-06: hsl(calc(360 / var(--steps) *  6 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-07: hsl(calc(360 / var(--steps) *  7 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-08: hsl(calc(360 / var(--steps) *  8 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-09: hsl(calc(360 / var(--steps) *  9 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-10: hsl(calc(360 / var(--steps) * 10 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-11: hsl(calc(360 / var(--steps) * 11 + var(--hue-offset)), var(--saturation), var(--lightness));
  --color-crayon: #202036;
  --bg-01-a: no-repeat left 0% top / 11% 0% linear-gradient(to right, var(--color-01), var(--color-02));
  --bg-01-b: no-repeat left 0% top / 11% 100% linear-gradient(to right, var(--color-01), var(--color-02));
  --bg-02-a: no-repeat left 11.11% bottom / 11% 0% linear-gradient(to right, var(--color-02), var(--color-03));
  --bg-02-b: no-repeat left 11.11% bottom / 11% 100% linear-gradient(to right, var(--color-02), var(--color-03));
  --bg-03-a: no-repeat left 22.22% top / 11% 0% linear-gradient(to right, var(--color-03), var(--color-04));
  --bg-03-b: no-repeat left 22.22% top / 11% 100% linear-gradient(to right, var(--color-03), var(--color-04));
  --bg-04-a: no-repeat left 33.33% bottom / 11% 0% linear-gradient(to right, var(--color-04), var(--color-05));
  --bg-04-b: no-repeat left 33.33% bottom / 11% 100% linear-gradient(to right, var(--color-04), var(--color-05));
  --bg-05-a: no-repeat left 44.44% top / 11% 0% linear-gradient(to right, var(--color-05), var(--color-06));
  --bg-05-b: no-repeat left 44.44% top / 11% 100% linear-gradient(to right, var(--color-05), var(--color-06));
  --bg-06-a: no-repeat left 55.55% bottom / 11% 0% linear-gradient(to right, var(--color-06), var(--color-07));
  --bg-06-b: no-repeat left 55.55% bottom / 11% 100% linear-gradient(to right, var(--color-06), var(--color-07));
  --bg-07-a: no-repeat left 66.66% top / 11% 0% linear-gradient(to right, var(--color-07), var(--color-08));
  --bg-07-b: no-repeat left 66.66% top / 11% 100% linear-gradient(to right, var(--color-07), var(--color-08));
  --bg-08-a: no-repeat left 77.77% bottom / 11% 0% linear-gradient(to right, var(--color-08), var(--color-09));
  --bg-08-b: no-repeat left 77.77% bottom / 11% 100% linear-gradient(to right, var(--color-08), var(--color-09));
  --bg-09-a: no-repeat left 88.88% top / 11% 0% linear-gradient(to right, var(--color-09), var(--color-10));
  --bg-09-b: no-repeat left 88.88% top / 11% 100% linear-gradient(to right, var(--color-09), var(--color-10));
  --bg-10-a: no-repeat left 99.99% bottom / 11% 0% linear-gradient(to right, var(--color-10), var(--color-11));
  --bg-10-b: no-repeat left 99.99% bottom / 12% 100% linear-gradient(to right, var(--color-10), var(--color-11));
}

.rainbow-marker-loader {
  height: 4rem;
  width: 20rem;
  max-width: 100%;
  border: .5rem solid var(--color-crayon);
  border-radius: .5rem;
  animation: infinite alternate rainbow-fill var(--duration) ease-in-out;
  box-sizing: border-box;
  position: relative;
  margin: 1rem;
  background:
    var(--bg-01-a),
    var(--bg-02-a),
    var(--bg-03-a),
    var(--bg-04-a),
    var(--bg-05-a),
    var(--bg-06-a),
    var(--bg-07-a),
    var(--bg-08-a),
    var(--bg-09-a),
    var(--bg-10-a);
}

.rainbow-marker-loader::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: left center;
  border-radius: .5rem;
  box-sizing: border-box;
  margin-top: -7.5%;
  margin-left: -2.5%;
  animation: infinite alternate move-marker var(--duration) ease-in-out;
  background:
    no-repeat left 0% top / 3rem 50% linear-gradient(to bottom right, transparent, transparent 40%, var(--color-crayon) 40%),
    no-repeat left 0% bottom / 3rem 50% linear-gradient(to top right, transparent, transparent 40%, var(--color-crayon) 40%),
    no-repeat left 3rem bottom / 100% 100% linear-gradient(var(--color-crayon), var(--color-crayon));
}

@keyframes move-marker {
  10% {
    transform: translate(5%, 100%) rotate(2.5deg);
  }
  20% {
    transform: translate(20%, 0) rotate(-5deg);
  }
  30% {
    transform: translate(30%, 100%) rotate(2.5deg);
  }
  40% {
    transform: translate(40%, 0) rotate(-5deg);
  }
  50% {
    transform: translate(50%, 100%) rotate(2.5deg);
  }
  60% {
    transform: translate(60%, 0) rotate(-5deg);
  }
  70% {
    transform: translate(70%, 100%) rotate(2.5deg);
  }
  80% {
    transform: translate(80%, 0) rotate(-5deg);
  }
  90% {
    transform: translate(90%, 100%) rotate(2.5deg);
  }
  100% {
    transform: translate(100%, 0) rotate(-5deg);
  }
}

@keyframes rainbow-fill {
  0% {
    background:
      var(--bg-01-a),
      var(--bg-02-a),
      var(--bg-03-a),
      var(--bg-04-a),
      var(--bg-05-a),
      var(--bg-06-a),
      var(--bg-07-a),
      var(--bg-08-a),
      var(--bg-09-a),
      var(--bg-10-a);
  }
  10% {
    background:
      var(--bg-01-b),
      var(--bg-02-a),
      var(--bg-03-a),
      var(--bg-04-a),
      var(--bg-05-a),
      var(--bg-06-a),
      var(--bg-07-a),
      var(--bg-08-a),
      var(--bg-09-a),
      var(--bg-10-a);
  }
  20% {
    background:
      var(--bg-01-b),
      var(--bg-02-b),
      var(--bg-03-a),
      var(--bg-04-a),
      var(--bg-05-a),
      var(--bg-06-a),
      var(--bg-07-a),
      var(--bg-08-a),
      var(--bg-09-a),
      var(--bg-10-a);
  }
  30% {
    background:
      var(--bg-01-b),
      var(--bg-02-b),
      var(--bg-03-b),
      var(--bg-04-a),
      var(--bg-05-a),
      var(--bg-06-a),
      var(--bg-07-a),
      var(--bg-08-a),
      var(--bg-09-a),
      var(--bg-10-a);
  }
  40% {
    background:
      var(--bg-01-b),
      var(--bg-02-b),
      var(--bg-03-b),
      var(--bg-04-b),
      var(--bg-05-a),
      var(--bg-06-a),
      var(--bg-07-a),
      var(--bg-08-a),
      var(--bg-09-a),
      var(--bg-10-a);
  }
  50% {
    background:
      var(--bg-01-b),
      var(--bg-02-b),
      var(--bg-03-b),
      var(--bg-04-b),
      var(--bg-05-b),
      var(--bg-06-a),
      var(--bg-07-a),
      var(--bg-08-a),
      var(--bg-09-a),
      var(--bg-10-a);
  }
  60% {
    background:
      var(--bg-01-b),
      var (--bg-02-b),
      var (--bg-03-b),
      var (--bg-04-b),
      var (--bg-05-b),
      var (--bg-06-b),
      var (--bg-07-a),
      var (--bg-08-a),
      var (--bg-09-a),
      var (--bg-10-a);
  }
  70% {
    background:
      var (--bg-01-b),
      var (--bg-02-b),
      var (--bg-03-b),
      var (--bg-04-b),
      var (--bg-05-b),
      var (--bg-06-b),
      var (--bg-07-b),
      var (--bg-08-a),
      var (--bg-09-a),
      var (--bg-10-a);
  }
  80% {
    background:
      var (--bg-01-b),
      var (--bg-02-b),
      var (--bg-03-b),
      var (--bg-04-b),
      var (--bg-05-b),
      var (--bg-06-b),
      var (--bg-07-b),
      var (--bg-08-b),
      var (--bg-09-a),
      var (--bg-10-a);
  }
  90% {
    background:
      var (--bg-01-b),
      var (--bg-02-b),
      var (--bg-03-b),
      var (--bg-04-b),
      var (--bg-05-b),
      var (--bg-06-b),
      var (--bg-07-b),
      var (--bg-08-b),
      var (--bg-09-b),
      var (--bg-10-a);
  }
  100% {
    background:
      var (--bg-01-b),
      var (--bg-02-b),
      var (--bg-03-b),
      var (--bg-04-b),
      var (--bg-05-b),
      var (--bg-06-b),
      var (--bg-07-b),
      var (--bg-08-b),
      var (--bg-09-b),
      var (--bg-10-b);
  }
}
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
.track-section {
  position: relative; /* Ensure the section is positioned relatively */
  padding: 2rem; /* Add some padding to the section */
  margin: 2rem; /* Add some margin to separate the section */
  border: 2px solid transparent; /* Base border */
}

/* Top-left corner */
.track-section::before {
  content: '';
  position: absolute;
  width: 50px; /* Width of the corner */
  height: 50px; /* Height of the corner */
  border-top: 4px solid #fff; /* Border thickness and color */
  border-left: 4px solid #fff; /* Border thickness and color */
  top: -2px; /* Adjust based on border width */
  left: -2px; /* Adjust based on border width */
  z-index: 1; /* Ensure it appears on top */
}

/* Bottom-right corner */
.track-section::after {
  content: '';
  position: absolute;
  width: 50px; /* Width of the corner */
  height: 50px; /* Height of the corner */
  border-bottom: 4px solid #fff; /* Border thickness and color */
  border-right: 4px solid #fff; /* Border thickness and color */
  bottom: -2px; /* Adjust based on border width */
  right: -2px; /* Adjust based on border width */
  z-index: 1; /* Ensure it appears on top */
}

/* Top-right corner */
.track-section .top-right {
  content: '';
  position: absolute;
  width: 50px; /* Width of the corner */
  height: 50px; /* Height of the corner */
  border-top: 4px solid #fff; /* Border thickness and color */
  border-right: 4px solid #fff; /* Border thickness and color */
  top: -2px; /* Adjust based on border width */
  right: -2px; /* Adjust based on border width */
  z-index: 1; /* Ensure it appears on top */
}

/* Bottom-left corner */
.track-section .bottom-left {
  content: '';
  position: absolute;
  width: 50px; /* Width of the corner */
  height: 50px; /* Height of the corner */
  border-bottom: 4px solid #fff; /* Border thickness and color */
  border-left: 4px solid #fff; /* Border thickness and color */
  bottom: -2px; /* Adjust based on border width */
  left: -2px; /* Adjust based on border width */
  z-index: 1; /* Ensure it appears on top */
}


/* Base styles to remove padding and margins */
.track-section body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.track-section .flex {
  display: flex;
}

.track-section .items-center {
  align-items: center;
}

.track-section .justify-center {
  justify-content: center;
}

.track-section .min-h-screen {
  min-height: 100vh;
}

.track-section .bg-gray-900 {
  background-color: #1a202c;
}

.track-section .text-gray-400 {
  color: #cbd5e0;
}

.track-section .border-separate {
  border-collapse: separate;
}

/* Add this to your CSS file */
.track-section .table-container {
  margin-top: 2rem; /* Adjust the value as needed to move the table down */
}


.track-section .space-y-6 > :not(:first-child) {
  margin-top: 1.5rem;
}

.track-section .text-sm {
  font-size: 0.875rem;
}

.track-section .bg-gray-800 {
  background-color: #2d3748;
}

.track-section .text-gray-500 {
  color: #a0aec0;
}

.track-section .p-3 {
  padding: 0.75rem;
}

.track-section .text-left {
  text-align: left;
}

.track-section .rounded-full {
  border-radius: 9999px;
}

.track-section .h-12 {
  height: 3rem;
}

.track-section .w-12 {
  width: 3rem;
}

.track-section .object-cover {
  object-fit: cover;
}

.track-section .ml-3 {
  margin-left: 0.75rem;
}

.track-section .font-bold {
  font-weight: 700;
}

.track-section .bg-green-400 {
  background-color: #48bb78;
}

.track-section .text-gray-50 {
  color: #f7fafc;
}

.track-section .rounded-md {
  border-radius: 0.375rem;
}

.track-section .px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.track-section .text-gray-100 {
  color: #f7fafc;
}

.track-section .bg-red-400 {
  background-color: #f56565;
}

.track-section .bg-yellow-400 {
  background-color: #ecc94b;
}

.track-section .mr-2 {
  margin-right: 0.5rem;
}

.track-section .mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.track-section .ml-2 {
  margin-left: 0.5rem;
}

.track-section .table {
  border-spacing: 0 15px;
}

.track-section .table tr {
  border-radius: 20px;
}

.track-section .table tr td:nth-child(n+4),
.track-section .table tr th:nth-child(n+4) {
  border-radius: 0 0.625rem 0.625rem 0;
}

.track-section .table tr td:nth-child(1),
.track-section .table tr th:nth-child(1) {
  border-radius: 0.625rem 0 0 0.625rem;
}

.track-section i {
  font-size: 1rem !important;
}

/* Custom styles for search bar */
.track-section .container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.track-section .search-wrapper {
  width: 350px;
  height: 100px;
  position: relative;
  top: 30%;
  -webkit-filter: url("#goo");
  filter: url("#goo");
}

.track-section #searchBox {
  position: absolute;
  text-align: center;
  top: 20%;
}

.track-section .inputSearch {
  font-family: 'Nunito';
  font-weight: 800;
  width: 300px;
  height: 40px;
  border-radius: 10px;
  margin-top: 5px;
  border: none;
  background: #333;
  outline: none;
  z-index: 2;
  color: #f9f9f9;
  cursor: text;
  padding: 0 10px;
  display: none;
}

.track-section .bubble {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -3px 0 -3px;
  text-align: center;
  width: 50px;
  height: 50px;
  background: #333;
  border-radius: 100%;
  transition: all 0.5s ease;
  z-index: -1;
}

.track-section .bubble p {
  margin: 0;
}

.track-section .animate {
  animation-name: bubbling;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.track-section .S {
  transition: color 1s;
}

@keyframes bubbling {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
/* Responsive table styles for screens up to 687px */
@media (max-width: 687px) {
  .track-section .overflow-auto {
    overflow-x: auto;
  }

  .track-section .table {
    width: 100%;
    font-size: 0.75rem; /* Smaller font size */
  }

  .track-section .table th,
  .track-section .table td {
    padding: 0.25rem; /* Smaller padding */
  }

  .track-section .table th {
    font-size: 0.75rem; /* Smaller header font size */
  }

  .track-section .table td {
    font-size: 0.75rem; /* Smaller cell font size */
  }

  .track-section .table tr {
    margin-bottom: 0.5rem; /* Less margin between rows */
  }
}

/* Even smaller styles for screens up to 518px */
@media (max-width: 518px) {
  .track-section .overflow-auto {
    overflow-x: auto;
  }

  .track-section .table {
    width: 100%;
    font-size: 0.59rem; /* Much smaller font size */
  }

  .track-section .table th,
  .track-section .table td {
    padding: 0.30rem; /* Much smaller padding */
  }

  .track-section .table th {
    font-size: 0.5rem; /* Much smaller header font size */
  }

  .track-section .table td {
    font-size: 0.59rem; /* Much smaller cell font size */
  }

  .track-section .table tr {
    margin-bottom: 0.30rem; /* Minimal margin between rows */
  }
}

/* Adjust font sizes and paddings for better readability */
.track-section .text-sm {
  font-size: 0.75rem;
}

.track-section .p-3 {
  padding: 0.5rem;
}

/* Close Button Styles */
.outer {
  position: relative;
  margin: auto;
  width: 30px;
  margin-top: 10px;
  cursor: pointer;
}

.inner {
  width: inherit;
  text-align: center;
}

label { 
  font-size: .62em; 
  line-height: 4em;
  text-transform: uppercase;
  color: #fff;
  transition: all .3s ease-in;
  opacity: 0;
  cursor: pointer;
}

.inner:before, .inner:after {
  position: absolute;
  content: '';
  height: 1px;
  width: inherit;
  background: #FFC107;
  left: 0;
  transition: all .3s ease-in;
}

.inner:before {
  top: 50%; 
  transform: rotate(45deg);  
}

.inner:after {  
  bottom: 50%;
  transform: rotate(-45deg);  
}

.outer:hover label {
  opacity: 1;
}

.outer:hover .inner:before,
.outer:hover .inner:after {
  transform: rotate(0);
}

.outer:hover .inner:before {
  top: 0;
}

.outer:hover .inner:after {
  bottom: 0;
}

/* Adjust the position of the close button */
.table-container {
  position: relative;
}

.close-button {
  position: absolute;
  top: -38px;
  right: -5px;
  z-index: 10;
}

/* Pagination Styles */
.pagination {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
  border-radius: 2px;
}

.pagination li {
  list-style-type: none;
}

.pagination li button {
  background: none;
  border: none;
  padding: 10px 15px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
}

.pagination li button:hover,
.pagination li.active button {
  background: rgba(255, 255, 255, 0.2);
}

.pagination li button:disabled {
  color: #555;
  cursor: not-allowed;
}

/* Add responsive adjustments if needed */
@media (max-width: 687px) {
  .table-container .close-button {
    top: -35px;
    right: -5px;
  }
}

@media (max-width: 518px) {
  .table-container .close-button {
    top: -35px;
    right: -5px;
  }
}

@media (max-width: 400px) {
  .track-section .table-container {
    margin-top: 0.55rem; /* Reduce margin-top for smaller screens */
  }

  .track-section .table {
    font-size: 0.2rem; /* Further reduce font size */
  }

  .track-section .table th,
  .track-section .table td {
    padding: 0.1rem; /* Further reduce padding */
  }

  .track-section .table tr {
    margin-bottom: 0.1rem; /* Reduce space between rows */
  }

  .track-section::before {
    content: '';
    position: absolute;
    width: 20px; /* Smaller width for smaller screens */
    height: 20px; /* Smaller height for smaller screens */
    border-top: 2px solid #fff; /* Thinner borders for smaller corners */
    border-left: 2px solid #fff; /* Thinner borders for smaller corners */
    top: -1px; /* Adjusted for smaller borders */
    left: -1px; /* Adjusted for smaller borders */
    z-index: 1; /* Ensure it appears on top */
  }
  .track-section::after {
    content: '';
    position: absolute;
    width: 20px; /* Smaller width for smaller screens */
    height: 20px; /* Smaller height for smaller screens */
    border-bottom: 2px solid #fff; /* Thinner borders for smaller corners */
    border-right: 2px solid #fff; /* Thinner borders for smaller corners */
    bottom: -12px; /* Adjusted for smaller borders */
    right: -1px; /* Adjusted for smaller borders */
    z-index: 1; /* Ensure it appears on top */
  }
  .track-section .top-right {
    content: '';
    position: absolute;
    width: 20px; /* Smaller width for smaller screens */
    height: 20px; /* Smaller height for smaller screens */
    border-top: 2px solid #fff; /* Thinner borders for smaller corners */
    border-right: 2px solid #fff; /* Thinner borders for smaller corners */
    top: -1px; /* Adjusted for smaller borders */
    right: -1px; /* Adjusted for smaller borders */
    z-index: 1; /* Ensure it appears on top */
  }
  .track-section .bottom-left {
    content: '';
    position: absolute;
    width: 20px; /* Smaller width for smaller screens */
    height: 20px; /* Smaller height for smaller screens */
    border-bottom: 2px solid #fff; /* Thinner borders for smaller corners */
    border-left: 2px solid #fff; /* Thinner borders for smaller corners */
    bottom: -12px; /* Adjusted for smaller borders */
    left: -1px; /* Adjusted for smaller borders */
    z-index: 1; /* Ensure it appears on top */
  }
}
@media (max-height: 642px) {
  .pagination li button {
    padding: 5px 10px; /* Smaller padding for pagination buttons */
    font-size: 0.55rem; /* Smaller font size for pagination text */
  }
  
  .pagination {
    margin: 0.1rem 0; /* Reduce top and bottom margin */
  }
}

